import styles from "./register-form.module.css";
import { TextField } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import ButtonUi from "../../ui/button-ui";
import { useRef, useState } from "react";
import { useSnackbar } from "notistack";
import * as fbq from "../../lib/fbpixel";
import { useRouter } from "next/router";

const RegisterForm = ({ title, register_text, closePopup }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [phone, setPhone] = useState("");
  function handleBillingPhone(value) {
    setPhone(value);
  }
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmationRef = useRef();
  const router = useRouter();

  async function registerUser() {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const passwordConfirmation = passwordConfirmationRef.current.value;

    if (!name) {
      enqueueSnackbar("Name must be filled", { variant: "warning" });
      return;
    }

    if (!email) {
      enqueueSnackbar("Email must be filled", { variant: "warning" });
      return;
    }

    if (!email.includes("@")) {
      enqueueSnackbar("Invalid email format", { variant: "warning" });
      return;
    }

    if (!phone || phone.length <= 7) {
      enqueueSnackbar("Phone must be filled or invalid phone number", {
        variant: "warning",
      });
      return;
    }
    if (!password) {
      enqueueSnackbar("Password must be filled", { variant: "warning" });

      return;
    }

    if (password !== passwordConfirmation) {
      enqueueSnackbar("Password confirmation not match with password", {
        variant: "warning",
      });
      return;
    }

    try {
      const api_url = process.env.API_URL;
      const payload = {
        name,
        email,
        phone,
        password,
        passwordConfirmation,
      };
      const response = await fetch(`${api_url}api/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const res = await response.json();

      if (!response.ok) {
        enqueueSnackbar(res.message, { variant: "warning" });
        return;
      }
      facebookPixelAndCAPI();
      enqueueSnackbar(res.message, { variant: "success" });
      // document.cookie = "RegisterPopup=false; Max-Age=" + 60 * 60 * 24 * 7;
      if (closePopup) {
        closePopup.hideAccountPopup();
      }
      router.push("/login");
    } catch (error) {
      console.error(error);
      enqueueSnackbar("something went wrong", { variant: "error" });
    }
  }

  // Facebook CAPI & PIXEL
  const facebookPixelAndCAPI = () => {
    const userData = JSON.parse(localStorage.getItem("_fbu")) ?? null;

    setTimeout(() => {
      const eventName = "CompleteRegistration";
      const payload = {
        event_name: eventName,
        event_id: fbq.eventId(),
        custom_data: {},
        user_data: userData,
      };
      // meta pixel
      fbq.event(eventName, payload.custom_data);

      // CAPI
      // const api_url = process.env.API_URL;
      fetch(`/api/facebook-conversion-api`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          // console.log(`Server Side Event: ${eventName} (${response.status})`);
          // console.log(payload);
        })
        .catch((error) => {
          // console.log(`Server Side Event: ${eventName} (${error.status})`);
        });
    }, 250);
  };

  return (
    <>
      <div className={styles.form}>
        <div className={styles.title}>{title}</div>
        <div className={styles.signup_form}>
          <TextField
            inputRef={nameRef}
            id="name-sign-up"
            label="Full Name"
            variant="standard"
            className={styles.last_name_input}
            type="text"
            required
          />
          <TextField
            inputRef={emailRef}
            id="email-sign-up"
            label="Email"
            variant="standard"
            className={styles.email_input}
            type="email"
            required
          />
          <MuiTelInput
            forceCallingCode
            disableFormatting
            className={styles.phone_input}
            id="customer_billing_phone"
            label="Handphone / WA"
            variant="standard"
            defaultCountry="ID"
            required
            value={phone}
            onChange={handleBillingPhone}
          />
          <TextField
            inputRef={passwordRef}
            id="pass-sign-up"
            label="Password"
            variant="standard"
            className={styles.password_input}
            type="password"
            required
          />
          <TextField
            inputRef={passwordConfirmationRef}
            id="pass-conf-sign-up"
            label="Password Confirmation"
            variant="standard"
            className={styles.password_confirmation_input}
            type="password"
            required
          />

          <div className={styles.button_register} onClick={registerUser}>
            <ButtonUi button_class="button_primary">{register_text}</ButtonUi>
          </div>
        </div>
      </div>
    </>
  );
};
export default RegisterForm;
